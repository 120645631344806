// app/components/FontList.tsx
import React, { useEffect, useState } from "react";
import { Font } from "~/types/fontTypes";
import { NavLink, useNavigate } from "@remix-run/react";
import Paywall from "./Paywall";
import { useDispatch } from "react-redux";
import { showLoginPopup } from "~/features/loginPopupSlice";
import { showProPopup } from "~/features/proPopupSlice";
import FontItemShimmer from "./FontItemShimmer";
import { To, useNavigation } from "react-router";

interface FontListProps {
  fonts: Font[];
  authenticated: boolean
  proUser: boolean
  totalFonts: number
}

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export const FontList: React.FC<FontListProps> = ({ fonts, authenticated, proUser, totalFonts }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const navigation = useNavigation();

  const handleNavigation = (path: To) => {
    setLoading(true);
    navigate(path);
  };

  useEffect(() => {
    if (navigation.state === 'idle') {
      setLoading(false);
    }
  }, [navigation.state]);

  return (
    <>
      {loading && <Loader />} {/* Show loader if loading is true */}
      <div className="font-list">
        {fonts.map((font) => (
          (font.is_premium && !proUser) ? (
            // if premium font, and if user is not pro, show locked card
            <FontItemShimmer key={font.slug} />
          ) : (
            <NavLink
              to={`/fonts/` + font.slug + `/`}
              key={font.slug}
              className={`font-item`}
              title={font.name}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation(`/fonts/${font.slug}/`);
              }}
            >
              <div
                className="font-icon"
                style={{ backgroundImage: `url(${font.icon_url})` }}
              ></div>
              <div className="font-details">
                <div className="font-name">{font.name}</div>
                <div className="font-foundry" title={font.foundary.name}>
                  {font.foundary.name}
                </div>
              </div>
              <div className="trial-tag">{font.price}</div>
            </NavLink>
          )
        ))}
        {!authenticated && (
          <>
            <div className="sidebar-login">
              <h4>Join Fontcert to explore more</h4>
              <p>Don't let font discovery eat up your valuable time.</p>
              <button type="button" onClick={() => dispatch(showLoginPopup())} className="light-btn" title="Join Fontcert">
                <span>Join for free</span>
                <img src="/icons/join-arrow.svg" alt="join fontcert" width={14} height={10} />
              </button>
              <button type="button" onClick={() => dispatch(showProPopup())} title="Go Pro">
                <span>Go Pro</span>
                <img src="/icons/pro-white.svg" alt="join fontcert" width={14} height={14} />
              </button>
              {/* <div className="sidebar-login-bottom">Already have an account? <span className="login-link" onClick={() => dispatch(showLoginPopup())}>Login now</span></div> */}
            </div>
          </>
        )}
        {(authenticated && !proUser) && (<Paywall totalFonts={totalFonts} />)}
      </div>
    </>
  );
};
